/** Basic modal component */

import { FunctionComponent, ComponentChildren } from 'preact';
import { IcoCheck, IcoExclamation } from '@components/icons';
import { autoFocus } from 'client/utils/autofocus';
import { useEsc } from 'client/utils/use-esc';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';

export type ModalMode = 'warn' | 'info' | 'ok' | 'none' | 'custom';

interface Props {
  onCancel: () => void;
  isOpen?: boolean;
  padding?: string;
  size?: string;
  flex?: string;
  dontAutoFocus?: boolean;
  children?: ComponentChildren;
}

interface TitleProps {
  centered?: boolean;
  children: ComponentChildren;
}

interface MessageProps {
  class?: string;
  children: ComponentChildren;
}

interface ContentProps {
  mode?: ModalMode;
  icon?: ComponentChildren;
  children: ComponentChildren;
}

export function ModalTitle({ centered, children }: TitleProps) {
  return (
    <h3
      class={`text-base font-semibold text-gray-900 dark:text-gray-200 mb-2 ${
        centered ? 'text-center' : ''
      }`}
    >
      {children}
    </h3>
  );
}

export const ModalMessage = ({ class: className = '', children }: MessageProps) => {
  return (
    <div class={`${className} text-sm leading-5 text-gray-500 dark:text-gray-300`}>{children}</div>
  );
};

export const ModalFooter: FunctionComponent = ({ children }) => {
  return <footer class="mt-4 flex justify-end space-x-3">{children}</footer>;
};

export function ModalContent({ mode = 'warn', icon, children }: ContentProps) {
  if (mode === 'none') {
    return <section>{children}</section>;
  }
  const modeColors: Record<ModalMode, string> = {
    none: '',
    custom: '',
    ok: 'bg-indigo-100',
    warn: 'bg-red-100 text-red-600',
    info: 'bg-indigo-500',
  };
  return (
    <div class="flex gap-4">
      <div
        class={`shrink-0 sm:flex items-center justify-center hidden rounded-full w-10 h-10 ${modeColors[mode]}`}
      >
        {mode === 'custom' && icon}
        {mode === 'warn' && <IcoExclamation class="h-6 w-6" />}
        {mode === 'info' && <i class="font-serif font-bold text-white text-xl">i</i>}
        {mode === 'ok' && <IcoCheck class="h-6 w-6" />}
      </div>
      <div class="flex-grow pt-1">{children}</div>
    </div>
  );
}

export const ModalWarning = ModalContent;

export function ModalOk({ children }: { children: ComponentChildren }) {
  return <ModalContent mode="ok">{children}</ModalContent>;
}

export function Modal({
  isOpen = true,
  size = 'sm:max-w-xl sm:w-full',
  padding = 'p-6 pb-4',
  flex = 'flex items-center justify-center',
  dontAutoFocus = false,
  onCancel,
  children,
}: Props) {
  useEsc(onCancel, { escapeOnly: true });

  // This prevents things like the date picker from moving all over weirdly
  // or the background from scrolling under the modal. Modals should always
  // have their own scroll and a max-height of the screen.
  useBodyScrollLock(true);

  if (!isOpen) {
    return null;
  }

  return (
    <div class="js-modal fixed z-50 inset-0" ref={dontAutoFocus ? undefined : autoFocus}>
      <div
        class="fixed inset-0 transition-opacity bg-gradient-to-b from-transparent to-black opacity-30"
        onClick={() => onCancel()}
      ></div>
      <div class={`${flex} h-screen w-screen`}>
        <div
          class={`inline-block bg-white dark:bg-gray-900 rounded-2xl drop-shadow-md shadow-xl transform transition-all mx-8 sm:my-8 sm:align-middle ${size} overflow-hidden`}
        >
          <div
            class={`inline-block ${padding} max-h-(screen-16) text-left ${size} overflow-y-auto mini-scroll`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
