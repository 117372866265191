import { ComponentChildren } from 'preact';
import { CourseLogo } from '@components/course-image';
import { PriceRow } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';
import { Offer } from './types';

type Price = Pick<
  PriceRow,
  'priceInCents' | 'paymentType' | 'currency' | 'numPayments' | 'interval'
>;

export function UpsellPriceSummary({
  price,
  listPrice,
  showPlaceholder,
}: {
  price: Price;
  listPrice?: number;
  showPlaceholder?: boolean;
}) {
  const intl = useIntl();
  const { initial, final } = pmtmath.batches({ price });
  const listPriceDesc = listPrice
    ? fmt.price({ currency: price.currency, priceInCents: listPrice })
    : '';
  return (
    <>
      {(listPriceDesc || showPlaceholder) && (
        <s class="opacity-75 mr-1">{listPriceDesc || 'List price'}</s>
      )}
      {initial && (
        <span class="font-semibold">
          {fmt.price(initial)}{' '}
          {fmt.firstBatchSuffix({
            item: initial,
            intl,
          })}
        </span>
      )}
      <span class={initial ? 'opacity-75' : listPrice ? 'text-green-600 font-semibold' : ''}>
        {`${initial ? ', then ' : ''}${fmt.price(final)} ${fmt.priceSuffix({
          item: final,
          intl,
        })}`}
      </span>
    </>
  );
}

export function ProductAndPricePreview({
  product,
  price,
  listPrice,
  children,
  showPlaceholder,
}: {
  product: Pick<Offer['product'], 'title' | 'imagePath'>;
  price?: Price;
  listPrice?: number;
  children?: ComponentChildren;
  showPlaceholder?: boolean;
}) {
  if (!product) {
    return null;
  }
  return (
    <span class="inline-flex items-center gap-4 min-w-96 group relative">
      <CourseLogo image={product.imagePath} />
      <span class="flex flex-col items-start">
        <span class="font-medium">{product.title}</span>
        <span class="text-gray-600">
          {price && (
            <UpsellPriceSummary
              price={price}
              listPrice={listPrice}
              showPlaceholder={showPlaceholder}
            />
          )}
        </span>
      </span>
      {children}
    </span>
  );
}
