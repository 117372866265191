import { showError } from '@components/app-error';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Case } from '@components/conditional';
import { DateInput } from '@components/date-picker';
import { IcoCalendar, IcoDocument } from '@components/icons';
import { Modal, ModalMessage, ModalFooter } from '@components/modal';
import { showModalForm } from '@components/modal-form';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { Toggle } from '@components/toggle';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import dayjs from 'dayjs';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import type { Course } from 'server/types';
import { toHumanTime, toLocalDate } from 'shared/dateutil';
import { URLS } from 'shared/urls';

interface Props {
  course: Pick<Course, 'id' | 'title' | 'accessFormat' | 'isBundle' | 'isProduct'>;
  onCancel: () => void;
}

const ACCESS_FORMAT_SCHEDULE_MESSAGES = {
  openaccess: {
    plural: 'messages and meetings',
    singular: 'message and meeting',
  },
  scheduled: {
    plural: 'modules, messages and meetings',
    singular: 'module, message and meeting',
  },
  ondemand: {
    plural: 'meetings',
    singular: 'meeting',
  },
};

export function showCopyCourseModal(course: Props['course']) {
  showModalForm(({ resolve }) => {
    return <CopyCourseModal course={course} onCancel={() => resolve(undefined)} />;
  });
}

export function CopyCourseModal({ course, onCancel }: Props) {
  const { id, title } = course;
  const { terminology } = useCurrentTenant();
  const courseLabel = course.isProduct
    ? 'product'
    : course.isBundle
    ? 'bundle'
    : terminology.course;

  const [isCopying, setIsCopying] = useState(false);
  const [shouldDraftContent, setShouldDraftContent] = useState(true);
  const [shouldCopyPricing, setShouldCopyPricing] = useState(false);
  const [isLoadingFirstDates, setIsLoadingFirstDates] = useState(true);

  const [firstDate, setFirstDate] = useState<
    RpxResponse<typeof rpx.courses.getCourseFirstEventDate> | undefined
  >(undefined);
  const [newDate, setNewDate] = useState<Date | undefined>();

  useAsyncEffect(async () => {
    try {
      const result = await rpx.courses.getCourseFirstEventDate({
        courseId: id,
      });
      if (result?.date) {
        const localDate = toLocalDate(result.date);
        setFirstDate({
          type: result.type,
          date: localDate?.toISOString(),
        });
        // If the first date is in the past, set the new date to now.
        const now = dayjs();
        setNewDate(now.isAfter(localDate) ? now.toDate() : localDate);
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsLoadingFirstDates(false);
    }
  }, [id]);

  async function copyCourse(values: { title: string }) {
    setIsCopying(true);

    try {
      const { newCourseId } = await rpx.courses.copyCourseAsGuide({
        id,
        title: values.title,
        shouldCopyPricing,
        startDateDiff:
          firstDate?.date && newDate ? dayjs(newDate).diff(firstDate.date, 'minute') : undefined,
        shouldDraftContent,
      });
      // For now, we're going to do a hard redirect to ensure we don't bork
      // any screen that doesn't properly handle changing course id.
      location.assign(URLS.guide.baseUrl({ id: newCourseId, isProduct: course.isProduct }));
    } catch (err) {
      showError(err);
    } finally {
      setIsCopying(false);
    }
  }

  return (
    <Modal isOpen onCancel={onCancel}>
      <AsyncForm onSubmit={copyCourse}>
        <header class="flex flex-col items-center">
          <IcoDocument class="w-12 h-12" />
          <h3 class="text-lg font-semibold text-gray-900 dark:text-gray-200 mb-2 capitalize">
            Copy {courseLabel}
          </h3>
        </header>

        <ModalMessage class="space-y-10 text-gray-700">
          <FormSection title={`${courseLabel} title`}>
            <p class="text-gray-500 mb-4">
              You're copying: <i>{title}</i>
            </p>
            <label class="block mb-2">What should we call the new copy of the {courseLabel}?</label>
            <FormGroup prop="title" class="w-full">
              <input
                type="text"
                placeholder={`${courseLabel} title`}
                name="title"
                class="ruz-input"
                defaultValue={`Copy of ${title}`}
              />
            </FormGroup>
          </FormSection>

          <FormSection title="Sales Page and Payments">
            <label class="flex mt-3">
              <Toggle
                class="mr-2"
                checked={shouldCopyPricing}
                onClick={() => setShouldCopyPricing(!shouldCopyPricing)}
              />
              <span>
                <span class="block">
                  Copy sales page, price points and coupons into the new {courseLabel}
                </span>
                <small class="block">
                  Note: Only the 50 most recently updated price points and coupons will be copied
                  into the new {courseLabel}.
                </small>
              </span>
            </label>
          </FormSection>

          <Case when={!isLoadingFirstDates} fallback={<Spinner />}>
            {firstDate?.date && (
              <FormSection title="Scheduling">
                <div class="space-y-4">
                  <label class="flex mt-3">
                    <Toggle
                      class="mr-2"
                      checked={shouldDraftContent}
                      onClick={() => setShouldDraftContent(!shouldDraftContent)}
                    />
                    <span>
                      <span class="block mb-1 text-gray-900">
                        Set all {ACCESS_FORMAT_SCHEDULE_MESSAGES[course.accessFormat].plural} in the
                        new course to "draft"
                      </span>
                      <small class="block">
                        If enabled, you'll need to manually schedule these in the new {courseLabel}.
                        Disable this if you want us to schedule everything automatically based on
                        the first {firstDate.type} date you will set below.
                      </small>
                    </span>
                  </label>

                  {!shouldDraftContent && (
                    <div class="space-y-4">
                      <div>
                        The first {firstDate.type} of {title} was scheduled to release on:{' '}
                        <strong>{toHumanTime(firstDate.date)}</strong> (student's timezone).
                      </div>
                      <div>
                        To schedule your new copy of this {courseLabel}, please set a date for this
                        first {firstDate.type}.
                      </div>
                      <div>
                        We'll adjust the remaining{' '}
                        {ACCESS_FORMAT_SCHEDULE_MESSAGES[course.accessFormat].singular} dates to
                        match the pacing in the original {courseLabel}.
                      </div>

                      <div class="flex items-center">
                        <IcoCalendar class="w-6 h-6 mr-2" />
                        <DateInput
                          wrapperClass="grow"
                          class="w-full"
                          name="newDate"
                          value={newDate}
                          onChange={setNewDate}
                          includeTime
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormSection>
            )}
          </Case>
        </ModalMessage>

        <ModalFooter>
          <BtnSecondary type="button" onClick={onCancel}>
            Cancel
          </BtnSecondary>
          <BtnPrimary disabled={isLoadingFirstDates} isLoading={isCopying}>
            Copy {courseLabel}
          </BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}

function FormSection({ title, children }: { title: string; children: ComponentChildren }) {
  return (
    <section>
      <h2 class="text-sm text-gray-900 font-bold mb-2 capitalize">{title}</h2>
      {children}
    </section>
  );
}
