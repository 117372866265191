import { Dispatch, StateUpdater, useMemo, useState } from 'preact/hooks';
import { IcoFilter, IcoGrid, IcoList, IcoX } from '@components/icons';
import { AutoPager } from '../../components/auto-pager';
import { Button } from '@components/buttons';
import { Course, Filters } from './types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { SearchBox } from '@components/search-box';
import { EmptyScreen } from './empty-screen';
import { intl, useIntl } from 'shared/intl/use-intl';
import { useLocalStorageState } from 'client/lib/hooks';
import { filterCourses } from './utils';
import { ComponentChildren } from 'preact';
import { showModalForm } from '@components/modal-form';
import { Modal } from '@components/modal';

export type ListType = 'grid' | 'list';

type Props = {
  primaryActions?: ComponentChildren;
  secondaryActions?: ComponentChildren;
  courses: Course[];
  renderItem: (props: {
    course: Course;
    listType: ListType;
    searchTerm: string;
    displayDate: 'createdAt' | 'lastOpenedAt';
  }) => JSX.Element;
  type: 'courses' | 'bundles';
};

const PAGE_SIZE = 20;

function RuzukuPromoLink() {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  if (user?.level !== 'student' || !tenant.isCore) {
    return null;
  }

  return (
    <div>
      <a
        class="flex flex-col p-6 rounded-lg border hover:border-indigo-600"
        href="https://www.ruzuku.com/?utm_source=student&utm_content=mycourses&utm_medium=link"
      >
        <span class="text-gray-600 mb-2">{intl('Curious about creating your own course?')}</span>
        <span>{intl('Learn more about how Ruzuku can help ⤑')}</span>
      </a>
    </div>
  );
}

export function CourseList(props: Props) {
  const intl = useIntl();
  const { type, courses } = props;
  const [filters, setFilters] = useState(defaultFilters);
  const [searchTerm, setRawSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const setSearchTerm = (term: string) => {
    setRawSearchTerm(term);
    setPageSize(PAGE_SIZE);
  };
  const [listType, setListType] = useLocalStorageState<ListType>('mycourses.listType', 'grid');
  const currentUser = useCurrentUser()!;
  const isStudentOnly = currentUser.level === 'student';

  const filtered = useMemo(() => {
    return filterCourses({
      courses,
      filters,
      type,
    }).sort((a, b) => {
      const v1 = a[filters.sort];
      const v2 = b[filters.sort];
      return v1 === v2 ? 0 : v1 > v2 ? -filters.sortDirection : filters.sortDirection;
    });
  }, [courses, filters, type, searchTerm]);

  const searched =
    searchTerm.length === 0 ? filtered : filtered.filter((c) => c.search.includes(searchTerm));

  const hasFilters = !areDefaultFilters(filters) || searchTerm.length > 0;
  const visibleCourses = searched.slice(0, pageSize);
  const hasMorePages = visibleCourses.length !== searched.length;

  return (
    <>
      <header class="grid md:grid-cols-3 gap-8 py-4 pb-10 mb-10 border-b">
        {props.primaryActions && <div>{props.primaryActions}</div>}
        <section>
          <div class="flex items-stretch">
            <Button
              class="bg-gray-50 text-gray-600 border border-r-0 border-gray-300 rounded-full rounded-r-none px-4 whitespace-nowrap hidden md:inline-flex items-center gap-2"
              onClick={() =>
                showModalForm(({ resolve }) => {
                  const [modalFilters, setModalFilters] = useState(filters);
                  const setAllFilters: typeof setModalFilters = (stateOrFn) => {
                    setModalFilters(stateOrFn);
                    setFilters(stateOrFn);
                  };

                  return (
                    <Modal
                      isOpen
                      onCancel={resolve}
                      size="w-content max-w-full"
                      flex="flex justify-center items-start pt-20"
                    >
                      <div class="flex gap-16 p-4" onClick={(e) => e.stopPropagation()}>
                        {isStudentOnly && (
                          <FilterOptions
                            field="sort"
                            currentValue={modalFilters.sort}
                            setFilters={setAllFilters}
                            title={intl('Sort by')}
                            options={[
                              { title: intl('Recently opened by me'), value: 'lastOpenedAt' },
                              { title: intl('Title'), value: 'search', sortDirection: -1 },
                            ]}
                          />
                        )}
                        {!isStudentOnly && (
                          <>
                            <FilterOptions
                              field="show"
                              currentValue={modalFilters.show}
                              setFilters={setAllFilters}
                              {...filterDefinitions.show}
                            />
                            <FilterOptions
                              field="sort"
                              currentValue={modalFilters.sort}
                              setFilters={setAllFilters}
                              {...filterDefinitions.sort}
                            />
                            <FilterOptions
                              field="status"
                              currentValue={modalFilters.status}
                              setFilters={setAllFilters}
                              {...filterDefinitions.status}
                            />
                            {type !== 'bundles' && (
                              <FilterOptions
                                field="type"
                                currentValue={modalFilters.type}
                                setFilters={setAllFilters}
                                {...filterDefinitions.type}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </Modal>
                  );
                })
              }
            >
              <IcoFilter />
              {intl('Filter')}
            </Button>
            <SearchBox
              class="ruz-input pl-9 text-sm rounded-full md:rounded-l-none"
              onTermChange={(term) => setSearchTerm(term.toLowerCase())}
              placeholder={intl('Find a {model:string}', {
                model: type === 'bundles' ? 'bundle' : 'course',
              })}
              value={searchTerm}
            />
          </div>
        </section>

        <nav class="hidden lg:flex items-center justify-end gap-2">
          {props.secondaryActions && (
            <>
              {props.secondaryActions}
              <span class="border-l h-6 mr-2"></span>
            </>
          )}
          <Button
            data-tooltip="Grid View"
            class={`${
              listType === 'grid' ? 'bg-gray-100' : ''
            } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
            onClick={() => setListType('grid')}
          >
            <span class="flex gap-3 items-center">
              <IcoGrid />
            </span>
          </Button>
          <Button
            data-tooltip="List View"
            class={`${
              listType === 'list' ? 'bg-gray-100' : ''
            } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
            onClick={() => setListType('list')}
          >
            <span class="flex gap-3 items-center">
              <IcoList />
            </span>
          </Button>
        </nav>
        {hasFilters && (
          <div class="flex items-center gap-2 justify-center col-span-full">
            <BtnClearFilter filters={filters} setFilters={setFilters} prop="show" />
            <BtnClearFilter filters={filters} setFilters={setFilters} prop="sort" />
            <BtnClearFilter filters={filters} setFilters={setFilters} prop="status" />
            <BtnClearFilter filters={filters} setFilters={setFilters} prop="type" />
            <Button
              class="inline-flex items-center gap-2 bg-gray-100 rounded-md p-1 pr-2 pl-3"
              onClick={() => {
                setFilters(defaultFilters);
                setSearchTerm('');
              }}
            >
              {intl('Clear filters')}
              <IcoX />
            </Button>
          </div>
        )}
      </header>
      {!filtered.length && (
        <EmptyScreen
          title={type === 'bundles' ? intl('No Bundles') : intl('No Courses')}
          subtext={
            type === 'bundles'
              ? `${
                  isStudentOnly
                    ? intl('When you register for a course, it will show up here.')
                    : intl("When you create courses, they'll show up here.")
                } `
              : intl('No courses found with the current filters.')
          }
        />
      )}
      <div
        class={
          listType === 'grid'
            ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'
            : 'flex flex-col gap-4'
        }
      >
        {visibleCourses.map((course) =>
          props.renderItem({
            course,
            searchTerm,
            listType,
            displayDate: filters.sort === 'createdAt' ? 'createdAt' : 'lastOpenedAt',
          }),
        )}
        {!hasFilters && type !== 'bundles' && <RuzukuPromoLink />}
      </div>
      {hasMorePages && <AutoPager onLoadMore={() => setPageSize((p) => p + PAGE_SIZE)} />}
    </>
  );
}

const filterDefinitions = {
  show: {
    title: 'Show',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Teaching'), value: 'teaching' },
      { title: intl('Attending'), value: 'attending' },
      { title: intl('Archived'), value: 'archived' },
    ],
  },
  sort: {
    title: 'Sort by',
    options: [
      { title: intl('Recently opened by me'), value: 'lastOpenedAt' },
      { title: intl('Created at'), value: 'createdAt' },
      { title: intl('Title'), value: 'search', sortDirection: -1 },
      { title: intl('Number of students'), value: 'numStudents' },
    ],
  },
  status: {
    title: 'Status',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Draft'), value: 'draft' },
      { title: intl('Signups open'), value: 'published' },
      { title: intl('Closed'), value: 'closed' },
    ],
  },
  type: {
    title: 'Type',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Drip (Individual)'), value: 'ondemand' },
      { title: intl('Full Access'), value: 'openaccess' },
      { title: intl('Drip (Calendar)'), value: 'scheduled' },
    ],
  },
};

const defaultFilters: Filters = {
  sort: 'lastOpenedAt',
  sortDirection: 1,
  status: 'all',
  type: 'all',
  show: 'all',
};

function FilterOptions<K extends keyof Filters>({
  title,
  field,
  options,
  currentValue,
  setFilters,
}: {
  title: string;
  field: K;
  currentValue: Filters[K];
  options: Array<{ title: string; value: any; sortDirection?: number }>;
  setFilters: Dispatch<StateUpdater<Filters>>;
}) {
  return (
    <div class="flex flex-col space-y-2 text-sm min-w-40">
      <h3 class="uppercase text-xs font-bold tracking-wider text-gray-500 border-b pb-2">
        {title}
      </h3>
      {options.map((o) => (
        <Button
          key={o.value}
          class={`${o.value === currentValue ? 'font-bold' : ''} text-left`}
          onClick={() =>
            setFilters((s) => ({
              ...s,
              [field]: o.value,
              sortDirection: o.sortDirection || s.sortDirection,
            }))
          }
        >
          {o.title}
        </Button>
      ))}
    </div>
  );
}

function areDefaultFilters(filters: Filters) {
  return (
    filters.show === defaultFilters.show &&
    filters.sort === defaultFilters.sort &&
    filters.status === defaultFilters.status &&
    filters.type === defaultFilters.type
  );
}

function BtnClearFilter({
  filters,
  setFilters,
  prop,
}: {
  filters: Filters;
  prop: keyof typeof filterDefinitions;
  setFilters: Dispatch<StateUpdater<Filters>>;
}) {
  if (filters[prop] === defaultFilters[prop]) {
    return null;
  }
  return (
    <Button
      class="inline-flex items-center gap-2 bg-gray-100 rounded-md p-1 pr-2 pl-3"
      onClick={() => {
        setFilters((x) => ({ ...x, [prop]: defaultFilters[prop] }));
      }}
    >
      {filterDefinitions[prop].title}
      {': '}
      {filterDefinitions[prop].options.find((x) => x.value === filters[prop])?.title}
      <IcoX />
    </Button>
  );
}
