/**
 * Manage courses view
 */

import { FixedPage, FixedContent } from '@components/fixed-page';
import { router, RouteProps } from '@components/router';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { CreateCourseModal, CreateBundleModal } from './create-course-modal';
import { TopBar } from '@components/top-bar';
import { IcoPlus } from '@components/icons';
import { useFlashError } from 'client/lib/flash-errors';
import { CopyCourseTemplateModal, onCourseDeleted } from '@components/course-action-modals';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Data, load, CourseType } from './types';
import { EmptyScreen } from './empty-screen';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { CreateCourseRibbon, Template } from './create-course-ribbon';
import { InstantCoursesButton } from './instant-courses-button';
import { FreeTierBanner } from '@components/guide-page/free-tier-banner';
import { CourseList } from './course-list';
import CourseItem from './course-item';
import { useIntl } from 'shared/intl/use-intl';
import { InvalidPaypalBanner } from '@components/guide-page/invalid-paypal-banner';
import { ProTierBanner } from '@components/guide-page/pro-tier-banner';
import { DownloadCsvButton } from '@components/download-csv-modal';
import { MyCoursesPageWrapper } from './my-courses-page-wrapper';

function TenantMigrationNotice() {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div
      class={`${
        isVisible ? 'block' : 'hidden'
      } shadow-xl rounded-2xl border p-12 max-w-96 mx-auto my-20 relative space-y-4`}
    >
      <h2 class="text-2xl">Hold tight!</h2>
      <p>
        We're moving your courses to the newest version of our hosting platform. You'll see them
        here soon.
      </p>
      <footer class="mt-4" onClick={() => setIsVisible(false)}>
        <BtnSecondary>Dismiss</BtnSecondary>
      </footer>
    </div>
  );
}

function Page(props: RouteProps<Data>) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { courses } = props.state;
  const me = useCurrentUser()!;
  const courseType: CourseType = (props.route.params.show as CourseType) || 'courses';
  const [showNewCourseModal, setShowNewCourseModal] = useState<{ isProduct: boolean } | undefined>(
    undefined,
  );
  const [showNewBundleModal, setShowNewBundleModal] = useState(false);
  const [showCopyTemplateModal, setShowCopyTemplateModal] = useState<Template | undefined>(
    undefined,
  );
  const [showNewRibbon, setShowNewRibbon] = useState(!!props.route.params.newRibbon);
  const isStudentOnly = me.level === 'student';
  const hideSubnav = useMemo(() => isStudentOnly && !courses.some((x) => x.isBundle), []);

  useEffect(() => {
    return onCourseDeleted(({ courseId }) => {
      props.setState((s) => ({
        ...s,
        courses: s.courses.filter((c) => c.id !== courseId),
      }));
    });
  });

  // Add hook to check for flashed error messages, display error modal if any exist
  useFlashError();

  return (
    <FixedPage title={intl('My Courses')}>
      <FixedContent class="bg-white">
        <TopBar />
        <ProTierBanner />
        <InvalidPaypalBanner />
        {!isStudentOnly && me.tier === 'free' && <FreeTierBanner class="mb-8" />}
        {!isStudentOnly && (
          <CreateCourseRibbon
            isOpen={showNewRibbon}
            onBlankCourse={setShowNewCourseModal}
            onTemplateCourse={(template) => setShowCopyTemplateModal(template)}
            hide={() => setShowNewRibbon(false)}
          />
        )}
        {tenant.isMigrating && <TenantMigrationNotice />}
        {courses.length === 0 && (
          <EmptyScreen
            title={courseType === 'bundles' ? intl('No Bundles') : intl('No Courses')}
            subtext={
              isStudentOnly
                ? intl('When you register for a course, it will show up here.')
                : intl(`When you create courses, they'll show up here.`)
            }
            displayBundlesButton={!isStudentOnly}
            onNewCourse={isStudentOnly ? undefined : () => setShowNewRibbon(true)}
          />
        )}
        {courses.length > 0 && (
          <MyCoursesPageWrapper
            tab={courseType === 'bundles' ? 'bundles' : 'courses'}
            hideSubnav={hideSubnav}
          >
            <CourseList
              primaryActions={
                <div class="flex items-center gap-4">
                  {!showNewRibbon && !isStudentOnly && (
                    <BtnPrimary
                      onClick={() =>
                        courseType === 'bundles'
                          ? setShowNewBundleModal(true)
                          : setShowNewRibbon(true)
                      }
                      class="pl-4 pr-5 rounded-full gap-2"
                    >
                      <IcoPlus />
                      {intl('Create')}
                    </BtnPrimary>
                  )}
                  {!isStudentOnly && courseType !== 'bundles' && <InstantCoursesButton />}
                </div>
              }
              secondaryActions={
                !isStudentOnly && (
                  <div class="md:inline-flex hidden">
                    <DownloadCsvButton type="guide" />
                  </div>
                )
              }
              courses={courses}
              type={courseType}
              renderItem={({ course, listType, searchTerm, displayDate }) => (
                <CourseItem
                  key={course.id}
                  listType={listType}
                  course={course}
                  highlightTerm={searchTerm}
                  displayDate={displayDate}
                  isArchived={!isStudentOnly && course.isArchived}
                />
              )}
            />
          </MyCoursesPageWrapper>
        )}
        {showNewCourseModal && (
          <CreateCourseModal
            isProduct={showNewCourseModal.isProduct}
            onCancel={() => setShowNewCourseModal(undefined)}
          />
        )}
        {showNewBundleModal && (
          <CreateBundleModal isProduct={false} onCancel={() => setShowNewBundleModal(false)} />
        )}
        {showCopyTemplateModal && (
          <CopyCourseTemplateModal
            course={showCopyTemplateModal}
            onCancel={() => setShowCopyTemplateModal(undefined)}
          />
        )}
      </FixedContent>
    </FixedPage>
  );
}

router.add({ url: 'manage/courses', render: Page, authLevel: 'student', load });
router.add({ url: 'manage', render: Page, authLevel: 'student', load });
router.add({ url: 'courses', render: Page, authLevel: 'student', load });
router.add({ url: 'home', render: Page, authLevel: 'student', load });
router.add({ url: '', render: Page, authLevel: 'student', load });
