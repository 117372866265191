import { router } from '@components/router';
import { UpsellsPage } from './upsells-page';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { Button } from '@components/buttons';
import { IcoArrowLeft, IcoDotsHorizontal, IcoEye, IcoPencil, IcoTrash } from '@components/icons';
import { ModalForm, showConfirmModal, showModalForm } from '@components/modal-form';
import { showError } from '@components/app-error';
import { Dropdown } from '@components/dropdown';
import { Toggle } from '@components/toggle';
import { showUpsellPreviewModal } from './upsell-preview';
import { rpx } from 'client/lib/rpx-client';
import { ApplyToTab } from './apply-to-tab';
import { FullUpsell } from './types';
import { OffersTab } from './offers-tab';

type Tab = 'offers' | 'applyto' | 'signups';

type State = {
  upsell: FullUpsell;
};

async function load(props: AppRoute): Promise<State> {
  const upsellId = props.params.id;
  const upsell = await rpx.upsells.getUpsell({ upsellId });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  rpx.upsells.updateUpsellOpenedAt({ upsellId }).catch(console.error);
  return { upsell };
}

type Props = LoadedProps<typeof load>;

function Tab(props: { href: string; text: string; count: number; isSelected: boolean }) {
  return (
    <Button
      href={props.href}
      class={`flex items-center gap-2 rounded-md font-semibold p-1 px-4 ${
        props.isSelected ? 'bg-indigo-100 text-indigo-800' : 'hover:bg-gray-100 text-inherit'
      }`}
    >
      <span>{props.text}</span>
      <span
        class={`hidden rounded-full px-2.5 text-xs font-medium sm:inline-flex items-center ml-auto ${
          props.isSelected ? 'bg-indigo-200' : 'bg-gray-100'
        }`}
      >
        {props.count}
      </span>
    </Button>
  );
}

function Page(props: Props) {
  const { setState } = props;
  const { upsell } = props.state;
  const tab: Tab = (props.route.params.tab as Tab) || 'applyto';

  const showRenameUpsellModal = () =>
    showModalForm(() => (
      <ModalForm
        title="Rename upsell"
        onSubmit={async (data) => {
          const updated = { ...upsell, title: data.title };
          await rpx.upsells.saveUpsell({
            upsellId: updated.id,
            isEnabled: updated.isEnabled,
            title: updated.title,
          });
          setState((s) => ({ ...s, upsell: updated }));
        }}
      >
        <input name="title" value={upsell.title} placeholder="Upsell title" class="ruz-input" />
      </ModalForm>
    ));

  const showDeleteUpsellModal = async () => {
    try {
      const ok = await showConfirmModal({
        title: 'Delete upsell',
        body: `This will permanently delete this upsell. All customers who purchased any items in this upsell will continue to have access to those items.`,
        confirmButtonText: 'Permanently Delete Upsell',
        mode: 'warn',
      });
      if (ok) {
        await rpx.upsells.deleteUpsell({ id: upsell.id });
        router.goto('/upsells');
      }
    } catch (err) {
      showError(err);
    }
  };

  return (
    <UpsellsPage title={upsell.title}>
      <section class="flex flex-col gap-4">
        <header class="flex flex-col py-4 border-b">
          <nav>
            <Button href="/upsells" class="gap-2 inline-flex items-center text-gray-500 text-sm">
              <IcoArrowLeft /> Back to upsells
            </Button>
          </nav>
          <div class="flex items-end gap-2">
            <h1 class="text-2xl">
              <button onClick={showRenameUpsellModal}>{upsell.title}</button>
            </h1>
            <Dropdown
              triggerClass="hover:bg-gray-100 size-8 rounded-full inline-flex items-center justify-center transition-all"
              hideDownIcon
              noPadding
              renderMenu={() => (
                <section class="p-2 flex flex-col">
                  <Button
                    class="flex items-center gap-2 p-2 rounded hover:bg-gray-100"
                    onClick={showRenameUpsellModal}
                  >
                    <IcoPencil />
                    <span>Rename upsell</span>
                  </Button>
                  <Button
                    class="flex items-center gap-2 p-2 rounded hover:bg-red-50 hover:text-red-600"
                    onClick={showDeleteUpsellModal}
                  >
                    <IcoTrash />
                    <span>Delete upsell</span>
                  </Button>
                </section>
              )}
            >
              <IcoDotsHorizontal />
            </Dropdown>

            {!!upsell.numOffers && (
              <nav class="ml-auto flex items-center gap-4 pb-1.5">
                <button
                  class="flex items-center gap-2 border rounded-full pl-3 pr-4 py-1 hover:bg-gray-50"
                  onClick={() => showUpsellPreviewModal(upsell as any /*TODO*/)}
                >
                  <IcoEye />
                  Preview
                </button>
                <label
                  class={`inline-flex items-center gap-1 rounded-full font-normal p-1 pr-3 cursor-pointer hover:bg-gray-50`}
                >
                  <Toggle
                    class="scale-75"
                    checked={upsell.isEnabled}
                    onClick={() => {
                      const isEnabled = !upsell.isEnabled;
                      rpx.upsells
                        .saveUpsell({
                          upsellId: upsell.id,
                          isEnabled,
                          title: upsell.title,
                        })
                        .catch(showError);
                      setState((s) => ({
                        ...s,
                        upsell: { ...s.upsell, isEnabled },
                      }));
                    }}
                  />
                  <span>Signups open</span>
                </label>
              </nav>
            )}
          </div>
        </header>

        <div class="flex gap-10 py-6">
          <nav class="flex flex-col gap-4 min-w-60">
            <Tab
              href="?tab=applyto"
              isSelected={tab === 'applyto'}
              text="Apply to"
              count={upsell.numAppliedPrices}
            />
            <Tab
              href="?tab=offers"
              isSelected={tab === 'offers'}
              text="Offers"
              count={upsell.numOffers}
            />
            <Tab
              href="?tab=signups"
              isSelected={tab === 'signups'}
              text="Signups"
              count={upsell.numSignups}
            />
          </nav>

          <section class="flex flex-col gap-4 flex-grow">
            {tab === 'applyto' && <ApplyToTab upsell={upsell} />}
            {tab === 'offers' && (
              <OffersTab
                upsell={upsell}
                offers={upsell.offers.map((o) => ({ ...o, id: o.productId }))}
                onReorder={(offers) => {
                  rpx.upsells
                    .reorderOffers({
                      upsellId: upsell.id,
                      productIds: offers.map((o) => o.productId),
                    })
                    .catch(showError);
                  setState((s) => ({ ...s, upsell: { ...s.upsell, offers } }));
                }}
              />
            )}
            {tab === 'signups' && <p>Signups coming soon...</p>}
          </section>
        </div>
      </section>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/:id', render: Page, authLevel: 'guide', load });
router.add({ url: 'upsells/:id/offers', render: Page, authLevel: 'guide', load });
router.add({ url: 'upsells/:id/applied-to', render: Page, authLevel: 'guide', load });
