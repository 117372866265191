/**
 * The root upsells page.
 */
import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { UpsellsPage } from './upsells-page';
import { EmptyPage } from '@components/empty-page';
import { IcoCreditCard, IcoPlus } from '@components/icons';
import { BtnPrimary, Button } from '@components/buttons';
import { fmtFullDate } from 'shared/dateutil';
import { pluralize } from 'shared/formatting';
import { useCurrentTenant } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';

async function load() {
  const upsells = await rpx.upsells.getMyUpsells();
  return { upsells };
}

type Props = LoadedProps<typeof load>;
type Upsell = Props['data']['upsells'][0];

function WarningNote({ upsell }: { upsell: Upsell }) {
  const { terminology } = useCurrentTenant();
  const message = !upsell.isEnabled
    ? 'Signups closed'
    : !upsell.numOffers
    ? 'No offers'
    : !upsell.numAppliedPrices
    ? `Not applied to any ${terminology.course} or product`
    : '';
  if (!message) {
    return null;
  }
  return (
    <span>
      <span class="inline-flex gap-1 items-center bg-yellow-50 border border-yellow-200 pl-1 pr-2 text-yellow-700 rounded-md mb-2 text-xs">
        {message}
      </span>
    </span>
  );
}

function Page({ state }: Props) {
  const { terminology } = useCurrentTenant();
  const newPageHref = 'upsells/new';
  return (
    <UpsellsPage
      buttons={
        state.upsells.length > 0 && (
          <BtnPrimary class="gap-2 pr-4 rounded-full" href={newPageHref}>
            <IcoPlus />
            Create a new upsell
          </BtnPrimary>
        )
      }
    >
      {!state.upsells.length && (
        <EmptyPage
          Ico={IcoCreditCard}
          title="Upsells"
          centeredDescription
          description={
            <>
              Increase your revenue by promoting {terminology.courses}, products, and special deals
              to your students during checkout.
            </>
          }
          actionText="Create a new upsell"
          href={newPageHref}
        />
      )}
      {state.upsells.length > 0 && (
        <section class="flex flex-col gap-4">
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
            {state.upsells.map((upsell) => (
              <Button
                key={upsell.id}
                class="flex flex-col gap-4 p-6 pb-4 border rounded-2xl text-left hover:bg-gray-50 text-inherit"
                href={`/upsells/${upsell.id}`}
              >
                <header class="flex flex-col">
                  <WarningNote upsell={upsell} />
                  <span class="font-semibold text-xl">{upsell.title}</span>
                  <span class="text-gray-600 text-xs">Created {fmtFullDate(upsell.createdAt)}</span>
                </header>
                <span class="flex justify-between items-center border-t pt-4">
                  <span class="text-xs flex gap-1 items-center">
                    <b>{upsell.numOffers}</b> {pluralize('offer', upsell.numOffers)}
                  </span>
                  <span class="text-xs flex gap-1 items-center">
                    <b>{upsell.numAppliedPrices}</b> applied
                  </span>
                  <span class="text-xs flex gap-1 items-center">
                    <b>0</b> signups
                  </span>
                </span>
              </Button>
            ))}
          </div>
        </section>
      )}
    </UpsellsPage>
  );
}

router.add({ url: 'upsells', render: Page, authLevel: 'guide', load });
